import { Expose, Type } from "class-transformer";
import { Entity } from "../entity";
import CourseDateDetail from "./courseDateDetail";
import moment from "moment";

export default class CourseDate extends Entity {
  @Expose() start: string = moment().format("YYYY-MM-DD");
  @Expose() end: string = moment().format("YYYY-MM-DD");

  @Type(() => CourseDateDetail)
  @Expose()
  details: CourseDateDetail[] = [];
}
