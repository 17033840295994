import { Expose } from "class-transformer";
import { Entity } from "../entity";

export default class CoursePriceStructureDetail extends Entity {
  @Expose() is_active!: boolean;
  @Expose() price!: number;
  @Expose() valid_until!: string | null;
  @Expose() type!: string;
  @Expose() standard_price = true;
}
